import moment from 'moment';
const ReadingTable = ({ type, startDate, endDate, readingHeader, categories, dataset }) => {
    dataset = dataset.filter((item) => { return item.seriesname != 'WSL' && item.seriesname != 'AL' });
    let values = [];
    if (categories.length > 0) {
        categories.forEach((item, index) => {
            let obj = {
                date: moment(item?.label).format('DD/MMM/YY'),
                time: moment(item?.label).format('HH:mm'),
            }
            values.push(obj);
        });
    }
    return (
        <>
            {dataset[0].data.length > 0 &&
                <div id="reading-table" style={{ display: "none" }}>
                    <div className="reading-table mt-3 bg-white">
                        <h4>{type} Readings</h4>
                        <div className="mb-2">
                            <span><strong>From: </strong> {startDate}</span>
                            <span className="ml-2"><strong>To:</strong> {endDate}</span>
                        </div>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    {readingHeader.length > 0 && readingHeader.map((item, index) => {
                                        return (<th key={index}>{item}</th>)
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {values.length > 0 && values.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item?.date}</td>
                                            <td>{item?.time}</td>
                                            {dataset.length > 0 && dataset.map((d, i) => {
                                                return (
                                                    <td key={i}>
                                                        {d.data[index]&&d.data[index].value}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </>
    )
}
export default ReadingTable;