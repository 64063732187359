import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useHistory, useParams, Link } from "react-router-dom";
import api from "services/api";
import ProjectName from "views/Project/ProjectName";
import { useEffect, useState } from "react";

const sensorSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  sensorType: Yup.string().required('This field is required'),
  latitude: Yup.string().required('This field is required'),
  longitude: Yup.string().required('This field is required'),
  altitude: Yup.string().required('This field is required'),
  alertLevel: Yup.number().required('This field is required'),
  workSuspensionLevel: Yup.string().required('This field is required'),
  sensorColor: Yup.string().required('This field is required'),
  measurementUnit: Yup.string().required('This field is required'),
});
const EditSensor = () => {
  const { id, sid } = useParams();
  const history = useHistory();
  const [item, setItem] = useState({});
  const getSensorById = async (sid) => {
    try {
      const res = await api.get(`/admin/sensor/` + sid);
      if (res?.ok) {
        setItem(res?.data);
      } else {
        toast.error('Error in connection');
      }
    } catch (err) {
      toast.error(err?.message);
    }
  }
  useEffect(() => {
    getSensorById(sid);
  }, [sid])
  const handleSubmit = async (values) => {
    try {
      const res = await api.patch(`/admin/sensors/` + sid, values);
      if (res && res?.ok) {
        toast.success(res?.message);
        history.push(`/admin/project/sensor-setting/` + id);
      } else {
        toast.error('Server connection error.');
      }
    } catch (err) {
      if (err?.errors) {
        toast.error(err?.errors[0]?.msg);
      } else {
        toast.error(err?.message);
      }
    }
  }
  return (
    <>
      <div className="container">
        <div className="shadow bg-white">
          <div className="px-4 pb-0 pt-4 mb-0">
            <h3 className="mt-0">Edit Sensor</h3>
            <ProjectName id={id} />
          </div>
          <hr />
          <Formik
            initialValues={{
              name: item?.name,
              sensorType: item?.sensorType || '',
              latitude: item?.latitude || '',
              longitude: item?.latitude || '',
              altitude: item?.altitude || '',
              alertLevel: item?.alertLevel || '',
              workSuspensionLevel: item?.workSuspensionLevel || '',
              sensorColor: item?.sensorColor || '',
              measurementUnit: item?.measurementUnit || '',
              enableReading1: item?.enableReading1 || false,
              enableReading2: item?.enableReading2 || false,
              enableReading3: item?.enableReading3 || false,
              enableReading4: item?.enableReading4 || false,
              enableReading5: item?.enableReading5 || false,
              enableReading6: item?.enableReading6 || false,
              isEnabled: item?.isEnabled || false
            }}
            enableReinitialize={true}
            validationSchema={sensorSchema}
            onSubmit={async (values) => {
              await handleSubmit(values);
            }}
          >
            <Form className="p-4">
              <div className="row">
                <div className="mb-3 col-md-6">
                  <label htmlFor="name">Sensor Name</label>
                  <Field id="name" name="name" className="form-control" />
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="name"
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="sensorType">Sensor Type</label>
                  <Field
                    id="sensorType"
                    name="sensorType"
                    className="form-control"
                  />
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="sensorType"
                  />
                </div>
                <div className="mb-3 col-md-4">
                  <label htmlFor="latitude">Latitude</label>
                  <Field
                    id="latitude"
                    name="latitude"
                    className="form-control"
                  />
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="latitude"
                  />
                </div>
                <div className="mb-3 col-md-4">
                  <label htmlFor="longitude">Longitude</label>
                  <Field
                    id="longitude"
                    name="longitude"
                    className="form-control"
                  />
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="longitude"
                  />
                </div>
                <div className="mb-3 col-md-4">
                  <label htmlFor="altitude">Altitude</label>
                  <Field
                    id="altitude"
                    name="altitude"
                    className="form-control"
                  />
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="altitude"
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="alertLevel">Alert Level</label>
                  <Field id="alertLevel" name="alertLevel" className="form-control" type="text" min="0.0001" />
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="alertLevel"
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="workSuspensionLevel">Work Suspension Level</label>
                  <Field
                    id="workSuspensionLevel"
                    name="workSuspensionLevel"
                    className="form-control"
                  />
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="workSuspensionLevel"
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="sensorColor">Sensor Color</label>
                  <Field
                    id="sensorColor"
                    name="sensorColor"
                    className="form-control"
                  />
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="sensorColor"
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="measurementUnit">Measurement Unit</label>
                  <Field
                    id="measurementUnit"
                    name="measurementUnit"
                    className="form-control"
                  />
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="measurementUnit"
                  />
                </div>
                <div className="mb-3 col-md-12">
                  <div className="custom-control custom-switch">
                    <Field
                      id="isEnabled"
                      name="isEnabled"
                      type="checkbox"
                      className="custom-control-input"
                    />
                    <label htmlFor="isEnabled" className="custom-control-label">Enable Graph</label>
                  </div>
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="isEnabled"
                  />
                </div>
                <div className="mb-3 col-md-12">
                  <div className="custom-control custom-switch">
                    <Field
                      id="enableReading1"
                      name="enableReading1"
                      type="checkbox"
                      className="custom-control-input"
                    />
                    <label htmlFor="enableReading1" className="custom-control-label">Enable Reading 1</label>
                  </div>
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="enableReading1"
                  />
                </div>
                <div className="mb-3 col-md-12">
                  <div className="custom-control custom-switch">
                    <Field
                      id="enableReading2"
                      name="enableReading2"
                      type="checkbox"
                      className="custom-control-input"
                    />
                    <label htmlFor="enableReading2" className="custom-control-label">Enable Reading 2</label>
                  </div>
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="enableReading2"
                  />
                </div>
                <div className="mb-3 col-md-12">
                  <div className="custom-control custom-switch">
                    <Field
                      id="enableReading3"
                      name="enableReading3"
                      type="checkbox"
                      className="custom-control-input"
                    />
                    <label htmlFor="enableReading3" className="custom-control-label">Enable Reading 3</label>
                  </div>
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="enableReading3"
                  />
                </div>
                <div className="mb-3 col-md-12">
                  <div className="custom-control custom-switch">
                    <Field
                      id="enableReading4"
                      name="enableReading4"
                      type="checkbox"
                      className="custom-control-input"
                    />
                    <label htmlFor="enableReading4" className="custom-control-label">Enable Reading 4</label>
                  </div>
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="enableReading4"
                  />
                </div>
                <div className="mb-3 col-md-12">
                  <div className="custom-control custom-switch">
                    <Field
                      id="enableReading5"
                      name="enableReading5"
                      type="checkbox"
                      className="custom-control-input"
                    />
                    <label htmlFor="enableReading5" className="custom-control-label">Enable Reading 5</label>
                  </div>
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="enableReading5"
                  />
                </div>

                <div className="mb-3 col-md-12">
                  <div className="custom-control custom-switch">
                    <Field
                      id="enableReading6"
                      name="enableReading6"
                      type="checkbox"
                      className="custom-control-input"
                    />
                    <label htmlFor="enableReading6" className="custom-control-label">Enable Reading 6</label>
                  </div>
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="enableReading6"
                  />
                </div>

                <div className="col-md-12">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                  <Link to={`/admin/project/sensor-setting/` + id} className="btn btn-secondary ml-2">Cancel</Link>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
};

export default EditSensor;
