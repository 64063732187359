
import { Card, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { role } from "index";
import AdminProjects from "./AdminProjects";
import UserProjects from "./UserProjects";
const ListProject = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover p-4 pb-0 shadow">
              <Card.Header className="d-flex justify-content-between align-items-center p-0">
                <Card.Title as="h4" className="w-75">
                  Projects
                </Card.Title>
                {role !== 'user' &&
                  <div className="w-25  d-flex justify-content-end">
                    <Link to={'/admin/project/create'} className="btn btn-primary">Add new</Link>
                  </div>
                }
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                {role === 'user' ? <UserProjects /> : <AdminProjects />}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default ListProject;
