import api from "./api";
export const getAllProjects = async () => {
    try {
        const res = await api.get(`/admin/projects`);
        if (res?.ok) {
            return res.data;
        }
        return [];
    } catch (err) {
        return [];
    }
}
export const getProjectById = async (id) => {
    try {
        const res = await api.get(`/admin/projects/` + id);
        if (res?.ok) {
            return res;
        }
        return false;
    } catch (err) {
        return false;
    }
}
export const getAllUsers = async () => {
    try {
        const res = await api.get(`/admin/users`);
        if (res?.ok) {
            return res.data;
        }
        return [];
    } catch (err) {
        return [];
    }
}
// assign user
export const assignUserToProject = async (values) => {
    try {
        const res = await api.post(`/admin/assign-users/`, values);
        if (res?.ok) {
            return res;
        }
        return false;
    } catch (err) {
        return false
    }
}
export const getAssignedUsersByProjectId = async (id) => {
    try {
        const res = await api.get(`/admin/assign-users/` + id);
        if (res?.ok) {
            return res.data;
        }
        return [];
    } catch (err) {
        return [];
    }
}
export const getUsersProjects = async () => {
    try {
        const res = await api.get(`/admin/user-projects`);
        if (res?.ok) {
            return res.data;
        }
        return [];
    }
    catch (err) {
        return [];
    }
}

export const deleteAssignedUserById = async (id) => {
    try {
        const res = await api.delete(`/admin/assign-users/` + id);
        if (res?.ok) {
            return res;
        }
        return false;
    } catch (err) {
        return false
    }
}

export const getSensorsByProjectId = async (projectId) => {
    try {
        const res = await api.get(`/admin/sensors/` + projectId);
        if (res?.ok) {
            return res?.data;
        }
        return [];
    } catch (err) {
        return [];
    }
}

export const getGraphsByProjectId = async (id) => {
    try {
        const res = await api.get(`/admin/graph-profiles/` + id);
        if (res?.ok) {
            return res?.data;
        }
        return [];
    } catch (err) {
        return [];
    }
}

export const getGraphProfileById = async (id) => {
    try {
        const res = await api.get(`/admin/graph-profile/` + id);
        if (res?.ok) {
            return res;
        }
        return false;
    } catch (err) {
        return false
    }
}