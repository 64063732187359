import MyTable from "components/Table";
import { useEffect } from "react";
import { useState } from "react";
import { FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";
import { getUsersProjects } from "services/APIHelpers";

const UserProjects = () => {
    const [items, setItems] = useState([]);
    const getProjects = async () => {
        const res = await getUsersProjects();
        if (res.length > 0) {
            let projectItems = [];
            res?.forEach((item) => {
                let projectItem = {
                    id: item?.projectId?._id,
                    name: item?.projectId?.name,
                    locationName: item?.projectId?.locationId?.name || '-',
                    instrumentName: item?.projectId?.instrumentName || '-',
                    contractorName: item?.projectId?.contractorName || '-',
                };
                projectItems.push(projectItem);
            });
            setItems(projectItems);
        }
    }
    useEffect(() => {
        getProjects();
    }, []);
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            hidden: true
        },
        {
            dataField: 'name',
            text: 'Project Name',
            sort: true
        },
        {
            dataField: 'locationName',
            text: 'Location Name',
            sort: true
        },
        {
            dataField: 'instrumentName',
            text: 'Instrument Name',
            sort: true
        },
        {
            dataField: 'contractorName',
            text: 'Company Name',
            sort: true
        },
        {
            dataField: 'action',
            text: 'Action',
            formatter: (cellContent, row) => {
                return (
                    <div key={cellContent}>
                        <Link to={`/admin/project/view/` + row?.id} className="btn btn-primary p-1 px-2" title="View">
                            <FiEye />
                        </Link>
                    </div>
                )
            },
            align: "center"
        }
    ];
    return (
        <>
            <MyTable items={items} columns={columns} />
        </>
    )
}
export default UserProjects;
