import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useHistory, useParams, Link } from "react-router-dom";
import api from "services/api";
import ProjectName from "views/Project/ProjectName";

const graphProfileSchema = Yup.object().shape({
  name: Yup.string().required('This field is required')
});
const CreateGraphProfile = () => {
  const { id } = useParams();
  const history = useHistory();
  const handleSubmit = async (values) => {
    values.projectId = id;
    try {
      const res = await api.post('/admin/graph-profiles', values);
      if (res && res?.ok) {
        toast.success(res?.message);
        history.push(`/admin/project/profile-setting/` + id);
      } else {
        toast.error('Server connection error.');
      }
    } catch (err) {
      if (err?.errors) {
        toast.error(err?.errors[0]?.msg);
      } else {
        toast.error(err?.message);
      }
    }
  }
  return (
    <>
      <div className="container">
        <div className="shadow bg-white">
          <div className="px-4 pb-0 pt-4 mb-0">
            <h3 className="mt-0">Create Graph Profile</h3>
            <ProjectName id={id} />
          </div>
          <hr />
          <Formik
            initialValues={{
              name: ''
            }}
            validationSchema={graphProfileSchema}
            onSubmit={async (values) => {
              await handleSubmit(values);
            }}
          >
            <Form className="p-4">
              <div className="row">
                <div className="mb-3 col-md-12">
                  <label htmlFor="name">Graph Profile Name</label>
                  <Field id="name" name="name" className="form-control" />
                  <ErrorMessage
                    component={"div"}
                    className="text-danger"
                    name="name"
                  />
                </div>
                <div className="col-md-12">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                  <Link to={`/admin/project/profile-setting/` + id} className="btn btn-secondary ml-2">Cancel</Link>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreateGraphProfile;
