import { useState } from "react";
import { useEffect } from "react";
import { getProjectById } from "services/APIHelpers";

const ProjectName = ({ id }) => {
    const [name, setName] = useState('');
    const getProjectDetails = async (id) => {
        const res = await getProjectById(id);
        if (res?.ok) {
            setName(res?.data?.name + "-" + res?.data?.locationId?.name);
        }
    }
    useEffect(() => {
        getProjectDetails(id);
    }, [id]);
    return (<div>Project: {name}</div>)
}
export default ProjectName;