import React, { useState, createContext } from "react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { getGraphsByProjectId } from "services/APIHelpers";
import { getProjectById } from "services/APIHelpers";
import Select from "react-select";
import { CustomCard } from "components/CustomCard";
import ProjectName from "./ProjectName";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import axios from 'axios';
import config from '../../config';

const api = axios.create({
  baseURL: config.app.excelUrl,
})

const appContext = createContext();

const ProjectView = () => {
  const { id } = useParams();
  const [projectDetails,setProjectDetails] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [project, setProject] = useState({});
  const [defaultOption, setDefaultOption] = useState({});
  const [options, setOptions] = useState([]);
  const [projectName, setProjectName] = useState();

  const updateProjectDetails = (data) =>{
    setProjectDetails(data)
  }

  const handlePdf = () => {
    const mainData = document.getElementById('render-pdf');
    html2canvas(mainData, {
      onclone: document => {
        document.getElementById("reading-table").style.display = "block";
      }
    }).then((canvas) => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF('p', 'mm', 'a4');
      doc.addImage(canvas, 'PNG', 5, position, imgWidth, imgHeight, '', 'FAST');
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 5, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
      doc.save('Project.pdf');
    });
  }

  function convertDate(dateString) {
    const [day, month, yearTime] = dateString.split('/');
    const [year, time] = yearTime.split(' ');
    const fullYear = '20' + year; // Assuming it's the 21st century
    return `${day}/${month}/${fullYear} ${time}`;
}
function convertDateRange(dateRange) {
  // Split the range by ' to '
  const [startDate, endDate] = dateRange.split(' to ');

  // Convert the year for each date
  const convertYear = date => {
      const [day, month, year] = date.split('/');
      return `${day}/${month}/20${year}`;
  };

  const newStartDate = convertYear(startDate);
  const newEndDate = convertYear(endDate);

  // Combine the new start and end dates
  return `${newStartDate} to ${newEndDate}`;
}

function convertISOToCustomFormat(isoDate) {
  const date = new Date(isoDate);

  // Get day, month, and year
  const day = String(date.getUTCDate()).padStart(2, '0');
  const year = date.getUTCFullYear();

  // Month names array
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthNames[date.getUTCMonth()];

  return `${day}/${month}/${year}`;
}


  const downloadExcel = async() => {
    try {

      if(projectDetails.categories && projectDetails.categories[0].category.length > 0){
        projectDetails.categories[0].category.forEach((item, index) => {
          projectDetails.categories[0].category[index].label = convertDate(item.label);
        })
      }
      if(projectDetails.chart && projectDetails.chart.caption != '' && projectDetails.chart.caption != undefined && projectDetails.chart.caption != null){
        projectDetails.chart.caption = convertDateRange(projectDetails.chart.caption);
      }
      projectDetails.project.startDate = projectDetails.project.startDate ?  convertISOToCustomFormat(projectDetails.project.startDate) : null;
      projectDetails.project.createdAt = projectDetails.project.createdAt ?  convertISOToCustomFormat(projectDetails.project.createdAt) : null;
      projectDetails.project.updatedAt = projectDetails.project.updatedAt ?  convertISOToCustomFormat(projectDetails.project.updatedAt) : null;
      projectDetails.project.calibrationDate = projectDetails.project.calibrationDate ?  convertISOToCustomFormat(projectDetails.project.calibrationDate) : null;
      projectDetails.project.locationId.createdAt = projectDetails.project.locationId.createdAt ?  convertISOToCustomFormat(projectDetails.project.locationId.createdAt) : null;
      projectDetails.project.locationId.updatedAt = projectDetails.project.locationId.updatedAt ?  convertISOToCustomFormat(projectDetails.project.locationId.updatedAt) : null;
      console.log(projectDetails);
      const res = await api.post('/generate_excel', projectDetails);
  
      // Extract the file URL from the response
      const fileUrl = res.data.excel_url;
  
      // Option 1: Using window.open to open the file in a new tab
      // window.open(fileUrl, '_blank');
  
      // Option 2: Programmatically create a link and click it
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = 'Project.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading Excel:', error);
    }
  }

  const downloadPdf = async() => {
    try {
      const res = await api.post('/generate_excel', projectDetails);
  
      // Extract the file URL from the response
      const fileUrl = res.data.pdf_url;
  
      // Option 1: Using window.open to open the file in a new tab
      // window.open(fileUrl, '_blank');
  
      // Option 2: Programmatically create a link and click it
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = 'Project.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading Excel:', error);
    }
  }

  const getProject = async (projectId) => {
    const res = await getProjectById(projectId);
    if (res?.ok) {
      setProjectName(res?.data?.name + "-" + res?.data?.locationId?.name)
      setProject(res?.data);
    }
  }
  const getGraphTypes = async (projectId) => {
    const res = await getGraphsByProjectId(projectId);
    if (res.length > 0) {
      let optionsData = [];
      res.forEach((item, index) => {
        let optionItem = {
          value: item?._id,
          label: item?.name,
        };
        if (index === 0) {
          setDefaultOption(optionItem);
        }
        optionsData.push(optionItem);
      });
      setOptions(optionsData);
    }
  };
  useEffect(() => {
    getProject(id);
    getGraphTypes(id);
  }, [id])
  return (
    <appContext.Provider value={{projectDetails,updateProjectDetails}}>
      <div className="container-fluid">
        <div className="shadow bg-white rounded p-4">
          <div className="bg-white" id="render-pdf">
            <div className="title mb-4 border-bottom pb-3">
              <ProjectName  id={id} />
              <hr />
              <h6>Project setting</h6>
              <div className="row">
                <div className="col-3">Instrument Name: {project?.instrumentName}</div>
              </div>
              <hr />
              <h6>Project information</h6>
              <div className="row">
                <div className="col-12">BCA Reference No.: {project?.bcaReference}</div>
                <div className="col-12">Contractor's Name: {project?.contractorName}</div>
                {project?.description && <div className="col-12">Project Details: {project?.description}</div>}
                {project?.locationId?.description && <div className="col-12">Location Details: {project?.locationId?.description}</div>}
                <div className="col-12">Start Date:<Moment format="DD/MMM/YY">{project?.startDate}</Moment></div>
                {project?.remarks && <div className="col-12">Remarks: {project?.remarks}</div>}
              </div>
              <hr />
              <h6>Instrument information</h6>
              <div className="row">
                <div className="col-3">Brand: {project?.instrumentBrand}</div>
                <div className="col-3">Model: {project?.instrumentModel}</div>
                <div className="col-3">Serial No.: {project?.instrumentSerialNo}</div>
                <div className="col-3">Calibration Date: <Moment format="DD/MMM/YY">{project?.calibrationDate}</Moment></div>
                <div className="col-3">Latitude: {project?.latitude}</div>
                <div className="col-3">Longitude: {project?.longitude}</div>
                <div className="col-3">Altitude: {project?.altitude}</div>
              </div>
              <hr />
              <div className="row">
                <div className="col-4">
                  <label className="d-block">From</label>
                  <DatePicker dateFormat={'dd/MMM/yy'} className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>
                <div className="col-4">
                  <label className="d-block">To</label>
                  <DatePicker dateFormat={'dd/MMM/yy'} className="form-control" selected={endDate} onChange={(date) => setEndDate(date)} />
                </div>
                <div className="col-4 gie-select">
                  <label className="d-block">Graph Type</label>
                  {defaultOption?.value && options &&
                    <Select
                      options={options}
                      defaultValue={defaultOption}
                      onChange={e => setDefaultOption(e)}
                    />
                  }
                </div>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-md-12 mb-3">
                  {defaultOption?.value &&
                    <CustomCard
                      project = {project}
                      projectName = {projectName}
                      graphId={defaultOption?.value}
                      startDate={startDate?.toISOString().substring(0, 10)}
                      endDate={endDate?.toISOString().substring(0, 10)}
                      type={defaultOption?.label}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <Link to={`/admin/projects/`} className="btn btn-secondary">Cancel</Link>
          {/* <button onClick={handlePdf} className="btn btn-primary ml-2">Download</button> */}
          <button onClick={downloadExcel} className="btn btn-primary ml-2">Download Report</button>
          {/* <button onClick={downloadPdf} className="btn btn-primary ml-2">Download Pdf</button> */}
        </div>
      </div>
    </appContext.Provider>  
  );
}
export default ProjectView;
export {appContext}
