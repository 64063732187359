import React from "react";
import routes from "routes";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";
import sidebarImage from "assets/img/sidebar-3.jpg";
import CreateProject from "views/Project/CreateProject";
import { role } from "index";
import CreateUser from "views/User/CreateUser";
import EditUser from "views/User/EditUser";
import GeneralSettings from "views/Account/GeneralSettings";
import AssignUser from "views/AssignedUser/AssignUser";
import EditProject from "views/Project/EditProject";
import GraphProfileList from "views/GraphProfile/GraphProfileList";
import CreateGraphProfile from "views/GraphProfile/CreateGraphProfile";
import EditGraphProfile from "views/GraphProfile/EditGraphProfile";
import CreateSensor from "views/sensor/CreateSensor";
import ListSensor from "views/sensor/ListSensor";
import EditSensor from "views/sensor/EditSensor";
import SelectSensor from "views/GraphProfile/SelectSensor";
import ProjectView from "views/Project/ProjectView";

function Admin() {
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const getRoutes = (routes) => {
    return routes
      .filter((val) => val.role.includes(role))
      .map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.layout + prop.path}
              render={(props) => <prop.component {...props} />}
              key={key}
            />
          );
        } else {
          return null;
        }
      });
  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);
  let routeContainer = [
    {
      path: "/admin/project/create",
      name: "Create project",
      component: CreateProject,
      role: ["superadmin", "admin"],
    },
    {
      path: "/admin/project/edit/:id",
      name: "Edit project",
      component: EditProject,
      role: ["superadmin", "admin"],
    },
    {
      path: "/admin/project/view/:id",
      name: "View project",
      component: ProjectView,
      role: ["user", "superadmin", "admin"],
    },
    {
      path: "/admin/project/sensor-setting/:id",
      name: "Sensor setting",
      component: ListSensor,
      role: ["superadmin", "admin"],
    },
    {
      path: "/admin/project/profile-setting/:id",
      name: "Graph Profile Setting",
      component: GraphProfileList,
      role: ["superadmin", "admin"],
    },
    {
      path: "/admin/user/create",
      name: "Create user",
      component: CreateUser,
      role: ["superadmin", "admin"],
    },
    {
      path: "/admin/user/edit/:id",
      name: "Edit user",
      component: EditUser,
      role: ["superadmin", "admin"],
    },
    {
      path: "/admin/assign-user/:id",
      name: "Assign user",
      component: AssignUser,
      role: ["superadmin", "admin"],
    },
    {
      path: "/admin/add-sensor/:id",
      component: CreateSensor,
      role: ["superadmin", "admin"],
    },
    {
      path: "/admin/sensor/:id/edit/:sid",
      component: EditSensor,
      role: ["superadmin", "admin"],
    },
    {
      path: "/admin/create-graph-profile/:id",
      component: CreateGraphProfile,
      role: ["superadmin", "admin"],
    },
    {
      path: "/admin/graph-profile/:id/edit/:gid",
      component: EditGraphProfile,
      role: ["superadmin", "admin"],
    },
    {
      path: "/admin/graph-profile/:pid/select-sensor/:gid",
      component: SelectSensor,
      role: ["superadmin", "admin"],
    },
    {
      path: "/admin/settings/general",
      component: GeneralSettings,
      role: ["superadmin", "user", "admin"],
    },
  ];

  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Switch>
              {getRoutes(routes)}
              {routeContainer
                ?.filter((route) => route.role?.includes(role))
                ?.map((prop, index) => (
                  <Route
                    path={prop.path}
                    render={(props) => <prop.component {...props} />}
                    key={index}
                  />
                ))}
              <Redirect
                from="*"
                to={role ? "/admin/dashboard" : "/admin/login"}
              />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
