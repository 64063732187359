import axios from 'axios'
import config from '../config'
const api = axios.create({
  baseURL: config.app.apiUrl,
})
api.defaults.withCredentials = true
api.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem('gie-user-data')
    ? JSON.parse(localStorage.getItem('gie-user-data')).accessToken
    : '';
  config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
  return config;
})

api.interceptors.response.use(
  async (response) => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response.data);
    }
  },
  async (error) => {
    const { response } = error;
    return Promise.reject(response.data)
  },
)

export default api;
