import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FiCheckCircle, FiEdit, FiX, FiXCircle } from "react-icons/fi";
import ProjectName from "views/Project/ProjectName";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import api from "services/api";
import { getSensorsByProjectId } from "services/APIHelpers";
import { useRef } from "react";

const ListSensor = () => {
  const { id } = useParams();
  const [items, setItems] = useState([]);
  const getSensors = async (projectId) => {
    const res = await getSensorsByProjectId(projectId);
    if (res.length > 0) {
      setItems(res);
    }
  };
  const handleDelete = async (sid) => {
    if (confirm("Are you sure?")) {
      try {
        const res = await api.delete(`/admin/sensors/` + sid);
        if (res?.ok) {
          toast.success(res?.message);
          getSensors(id);
        }
      } catch (err) {
        toast.error(err?.message);
      }
    }
  };
  useEffect(() => {
    getSensors(id);
  }, [id]);

  const fileRef = useRef();
  const zipRef = useRef();
  const handleFile = async (e) => {
    let targetFile = e.target.files[0];
    if (targetFile?.name?.split(".")?.slice(-1)?.join() !== "pro") {
      toast.error("Please enter valid file");
      return false;
    }
    try {
      let formData = new FormData();
      formData.append("file", targetFile);
      formData.append("projectId", id);
      const data = await api.post("/admin/pro/upload", formData);
      if (data?.ok) {
        toast.success(data.message);
        getSensors(id);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const
    allowedTypes = [
      'application/zip'
      ,
      // .zip
      'application/x-zip-compressed'
      ,
      // .zip (alternative)
      'multipart/x-zip'
      ,
      // .zip (alternative)
      'application/x-7z-compressed'
      ,
      // .7z
      'application/x-rar-compressed'
      ,
      // .rar
      'application/x-tar'
      ,
      // .tar
      'application/gzip'
      ,
      // .gz
      'application/x-bzip2'
      ,
      // .bz2
      'application/x-xz'
      // .xz
    ];

  const handleZipFile = async (e) => {
    let targetFile = e.target.files[0];

    if(!allowedTypes.includes(targetFile.type)) {
      toast.error("Please upload a supported compressed file (e.g., zip, rar, 7z).");
      return false;
    }
    try {
      let formData = new FormData();
      formData.append("zipFile", targetFile);
      formData.append("projectId", id);
      const data = await api.post("/admin/zip/upload", formData);
      if (data?.ok) {
        toast.success(data.message);
        getSensors(id);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <input
        type="file"
        name="file"
        hidden
        ref={fileRef}
        onChange={handleFile}
      />
      <input
        type="file"
        name="zipFile"
        hidden
        ref={zipRef}
        onChange={handleZipFile}
      />
      <Container fluid>
        <div className="shadow bg-white shadow-lg rounded-lg p-4">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h3>Sensor Setting</h3>
              <ProjectName id={id} />
            </div>
            <div className="col-md-6 text-right">
              <button
                className="btn btn-secondary"
                onClick={() => zipRef?.current?.click()}
              >
                Import Zip
              </button>
              <button
                className="btn btn-primary ml-2"
                onClick={() => fileRef?.current?.click()}
              >
                Import File
              </button>
            </div>
          </div>
          <hr className="mb-4" />
          <div className="sensor-table mb-4">
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th rowSpan={2}>Enabled</th>
                  <th rowSpan={2}>Sensor Name</th>
                  <th rowSpan={2}>Sensor Type</th>
                  <th colSpan={3} className="text-center">
                    Position
                  </th>
                  <th colSpan={2}>Trigger Level</th>
                  <th rowSpan={2}>Sensor Color</th>
                  <th colSpan={6}>Display Fields</th>
                  <th rowSpan={2}>Display Unit</th>
                  <th rowSpan={2}>Action</th>
                </tr>
                <tr>
                  <th>Easting</th>
                  <th>Northing</th>
                  <th>RL</th>
                  <th>AL (+/-)</th>
                  <th>WSL (+/-)</th>
                  <th>Reading 1</th>
                  <th>Reading 2</th>
                  <th>Reading 3</th>
                  <th>Reading 4</th>
                  <th>Reading 5</th>
                  <th>Reading 6</th>
                </tr>
              </thead>
              <tbody>
                {items.length > 0 ? (
                  <>
                    {items.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {item?.isEnabled ? (
                              <div className="text-success">
                                <FiCheckCircle />
                              </div>
                            ) : (
                              <div className="text-danger">
                                <FiXCircle />
                              </div>
                            )}
                          </td>
                          <td>{item.name}</td>
                          <td>{item?.sensorType}</td>
                          <td>{item?.latitude}</td>
                          <td>{item?.longitude}</td>
                          <td>{item?.altitude}</td>
                          <td>{item?.alertLevel}</td>
                          <td>{item?.workSuspensionLevel}</td>
                          <td>{item?.sensorColor}</td>
                          <td>
                            {item?.enableReading1 ? (
                              <div className="text-success">
                                <FiCheckCircle />
                              </div>
                            ) : (
                              <div className="text-danger">
                                <FiXCircle />
                              </div>
                            )}
                          </td>
                          <td>
                            {item?.enableReading2 ? (
                              <div className="text-success">
                                <FiCheckCircle />
                              </div>
                            ) : (
                              <div className="text-danger">
                                <FiXCircle />
                              </div>
                            )}
                          </td>
                          <td>
                            {item?.enableReading3 ? (
                              <div className="text-success">
                                <FiCheckCircle />
                              </div>
                            ) : (
                              <div className="text-danger">
                                <FiXCircle />
                              </div>
                            )}
                          </td>
                          <td>
                            {item?.enableReading4 ? (
                              <div className="text-success">
                                <FiCheckCircle />
                              </div>
                            ) : (
                              <div className="text-danger">
                                <FiXCircle />
                              </div>
                            )}
                          </td>
                          <td>
                            {item?.enableReading5 ? (
                              <div className="text-success">
                                <FiCheckCircle />
                              </div>
                            ) : (
                              <div className="text-danger">
                                <FiXCircle />
                              </div>
                            )}
                          </td>
                          <td>
                            {item?.enableReading6 ? (
                              <div className="text-success">
                                <FiCheckCircle />
                              </div>
                            ) : (
                              <div className="text-danger">
                                <FiXCircle />
                              </div>
                            )}
                          </td>
                          <td>{item?.measurementUnit}</td>
                          <td>
                            <Link
                              to={`/admin/sensor/` + id + `/edit/` + item._id}
                              className="btn btn-primary p-1 px-2"
                            >
                              <FiEdit />
                            </Link>
                            <button
                              type="button"
                              onClick={() => handleDelete(item?._id)}
                              className="btn btn-danger ml-2 p-1 px-2"
                            >
                              <FiX />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td colSpan={17}>No sensors found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div>
            <Link to={`/admin/add-sensor/` + id} className="btn btn-primary">
              Add new
            </Link>
            <Link to={`/admin/projects/`} className="ml-2 btn btn-secondary">
              Cancel
            </Link>
          </div>
        </div>
      </Container>
    </>
  );
};
export default ListSensor;
