import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const MyTable = ({ items, columns }) => {
    const emptyDataMessage = () => { return 'No result found.'; }
    return (
        <>
            <div className='myTable' key={Math.random()}>
                <BootstrapTable
                    bootstrap4
                    keyField={'id'}
                    data={items} columns={columns}
                    pagination={paginationFactory()}
                    noDataIndication={emptyDataMessage}
                />
            </div>
        </>
    );
}
export default MyTable;
