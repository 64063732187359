import Dashboard from "views/Dashboard";
import ListProject from "views/Project/ListProject";
import ListUser from "views/User/ListUser";
import Login from "views/Login";
import AccountSetting from "views/Account/AccountSetting";
import ListAssignedUser from "views/AssignedUser/ListAssignedUser";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Project Dashboard",
    icon: "nc-icon nc-paper-2",
    component: ListProject,
    layout: "/admin",
    role: ["superadmin", "admin", "user"],
  },
  {
    path: "/users",
    name: "Users",
    icon: "nc-icon nc-circle-09",
    component: ListUser,
    layout: "/admin",
    role: ["superadmin", "admin"],
  },
  {
    path: "/assigned-users",
    name: "Assigned Users",
    icon: "nc-icon nc-badge",
    component: ListAssignedUser,
    layout: "/admin",
    role: ["superadmin", "admin"],
  },

  {
    path: "/account-setting",
    name: "Account setting",
    icon: "nc-icon nc-preferences-circle-rotate",
    component: AccountSetting,
    layout: "/admin",
    role: ["superadmin", "admin", "user"],
  },
  {
    path: "/login",
    name: "Sign In",
    icon: "nc-icon nc-lock-circle-open",
    component: Login,
    layout: "/admin",
    role: [undefined],
  },
];

export default dashboardRoutes;
