import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import {appContext} from '../../views/Project/ProjectView'
import { useContext, useEffect, useLayoutEffect, useState } from 'react'
charts(FusionCharts);
import React from "react";
const Chart = ({
  caption,
  subcaption,
  categories,
  dataset,
  yaxisname,
  measurementUnit,
  project,
  projectName
}) => {
  const dataSource = {
    project: project,
    projectName:projectName,
    chart: {
      caption: caption,
      yaxisname,
      subcaption: subcaption,
      showvalues: "0",
      plottooltext: "$seriesName{br}$label{br}Value: $dataValue " + measurementUnit,
      decimals: "4",
      slantLabel: "1",
    },
    categories: [
      {
        category: categories
      }
    ],
    dataset
  };

  const context = useContext(appContext)
  useEffect(()=>{
    context.updateProjectDetails(dataSource)
  },[project, projectName, caption, subcaption, categories, dataset, yaxisname, measurementUnit])
  return (
    <div>
      <ReactFusioncharts
        type="msline"
        width="100%"
        height="500"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    </div>
  );
};

export default Chart;