import Listing from "components/Listing";
import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ProjectName from "views/Project/ProjectName";
import { getSensorsByProjectId } from "services/APIHelpers";
import { getGraphProfileById } from "services/APIHelpers";
import api from "services/api";

const SelectSensor = () => {
    const { pid, gid } = useParams();
    const history = useHistory();
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [error, setError] = useState('');
    const getSensors = async (pid) => {
        const res = await getSensorsByProjectId(pid);
        if (res.length > 0) {
            let itemsData = [];
            res.forEach((item) => {
                const itemData = {
                    id: item._id,
                    name: item?.name || ''
                }
                itemsData.push(itemData);
            });
            setItems(itemsData);
        }
    }
    const getGraphProfile = async (id) => {
        const res = await getGraphProfileById(id);
        if (res?.ok && res?.data) {
            let itemsData = [];
            res?.data.sensors.forEach((item) => {
                const itemData = {
                    id: item?._id,
                    name: item?.name || ''
                }
                itemsData.push(itemData);
            });
            setSelectedItems(itemsData);
        }
    }
    useEffect(() => {
        getSensors(pid);
        getGraphProfile(gid);
    }, [pid, gid]);
    const handleSubmit = async () => {
        if (selectedItems.length === 0) {
            setError('Please add at least one sensor.');
        } else {
            setError('');
            const res = selectedItems.map(({ id }) => id);
            const values = {
                sensors: res,
                graphProfileId: gid
            };
            const response = await api.post('/admin/graph-profile/select-sensors', values);
            if (response && response?.ok) {
                toast.success(response?.message);
                history.push(`/admin/project/profile-setting/` + pid);
            } else {
                toast.error('Error in selecting sensors.');
            }
        }
    }
    return (
        <>
            <Container fluid>
                <div className="shadow bg-white shadow-lg rounded-lg p-4">
                    <div>
                        <h3>Select Sensors</h3>
                        <ProjectName id={pid} />
                    </div>
                    <hr className="mb-4" />
                    {items.length > 0 && <Listing items={items} selectedItems={selectedItems} setSelectedItems={setSelectedItems} />}
                    {error && <div className="text-danger">{error}</div>}
                    <div className="text-right d-block">
                        <Button variant="primary" className="mr-4" size="md" onClick={() => handleSubmit()}>
                            Save
                        </Button>
                        <Link to={`/admin/project/profile-setting/` + pid} className="btn btn-secondary">Cancel</Link>
                    </div>
                </div>
            </Container >
        </>
    );
}
export default SelectSensor;