import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import api from "services/api";

const settingsSchema = Yup.object().shape({
  oldPassword: Yup.string().required("This field is required"),
  newPassword: Yup.string().required("This field is required"),
  confirmPassword: Yup.string()
    .required("This field is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

const SecuritySettings = () => {
  const handleSubmit = async (data) => {
    try {
      const res = await api.post("/admin/change-password", data);
      if (res && res?.ok) {
        toast.success(res?.message);
      } else {
        toast.error("Error in connection");
      }
    } catch (err) {
      if (err?.errors) {
        toast.error(err?.errors[0]?.msg);
      } else {
        toast.error(err?.message);
      }
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={settingsSchema}
        onSubmit={async (values) => {
          await handleSubmit(values);
        }}
      >
        <Form>
          <div className="form-group">
            <label htmlFor="oldPassword">Old Password</label>
            <Field
              id="oldPassword"
              name="oldPassword"
              className="form-control"
              type="password"
            />
            <ErrorMessage
              component={"div"}
              className="text-danger"
              name="oldPassword"
            />
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">New Password</label>
            <Field
              id="newPassword"
              name="newPassword"
              className="form-control"
              type="password"
            />
            <ErrorMessage
              component={"div"}
              className="text-danger"
              name="newPassword"
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm New Password</label>
            <Field
              id="confirmPassword"
              name="confirmPassword"
              className="form-control"
              type="password"
            />
            <ErrorMessage
              component={"div"}
              className="text-danger"
              name="confirmPassword"
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default SecuritySettings;
