import { useState } from "react";
import { Button, FormControl } from "react-bootstrap";

const Listing = ({ selectedItems, items, setSelectedItems }) => {
  const [allItems, setAllItems] = useState(items);
  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setAllItems(items);
    } else {
      let availableItems = allItems.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setAllItems(availableItems);
    }
  };
  const handleRemoveItem = (item) => {
    setSelectedItems(
      selectedItems.filter((selectedItem) => selectedItem.id !== item.id)
    );
  };
  const handleRemoveAll = () => {
    setSelectedItems([]);
  };
  const handleAddAll = () => {
    const selectedIds = selectedItems.map((item) => item.id);
    const newSelectedItems = [
      ...selectedItems,
      ...allItems.filter((item) => !selectedIds.includes(item.id)),
    ];
    setSelectedItems(newSelectedItems);
  };
  const handleAddItem = (item) => {
    if (!selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
      setSelectedItems([...selectedItems, item]);
    }
  };
  const renderTable = (items, onClick, actionText, sectionText) => (
    <div className="table-responsive">
      <div className="mb-3">
        <div className="row align-items-center justify-content-between">
          <div className="col">
            <h6>{sectionText}</h6>
          </div>
          <div className="col">
            {actionText != "danger" ? (
              <FormControl
                type="text"
                placeholder="Search"
                onChange={handleSearchChange}
              />
            ) : (
              ""
            )}
          </div>
          <div className="col-auto">
            <Button
              variant={actionText}
              onClick={actionText === "danger" ? handleRemoveAll : handleAddAll}
              size="sm"
            >
              {actionText === "danger" ? "Remove All" : "Add All"}
            </Button>
          </div>
        </div>
      </div>
      <div className="overflow-scroll h-240-min">
        <table className="table">
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td></td>
                <td className="text-end">
                  <div
                    onClick={() => onClick(item)}
                    className={`text-${actionText} cursor-pointer border-0 d-inline-flex align-items-center `}
                  >
                    <i
                      className={`
                        fa fa-${actionText === "danger" ? "minus" : "plus"}
                        ${
                          selectedItems.some(
                            (selectedItem) => selectedItem.id === item.id
                          ) && actionText != "danger"
                            ? "text-faded cursor-auto"
                            : "plus"
                        }
                      `}
                    ></i>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
  return (
    <>
      <div className="border rounded mb-3 p-3">
        <div className="row">
          <div className="col-md-6">
            {renderTable(
              selectedItems,
              handleRemoveItem,
              "danger",
              "Selected Items"
            )}
          </div>
          <div className="col-md-6">
            {renderTable(allItems, handleAddItem, "primary", "Available Items")}
          </div>
        </div>
      </div>
    </>
  );
};
export default Listing;
