import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from "react-toastify";
import api from "services/api";
import { useHistory, useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";

const userSchema = Yup.object().shape({
    userName: Yup.string().required('Username is required'),
    email: Yup.string().email('Enter a valid email'),
    name: Yup.string().required('Name field is required'),
});
const EditUser = () => {
    const history = useHistory();
    const { id } = useParams();
    const [user, setUser] = useState({});
    const getUserByID = async (id) => {
        try {
            const res = await api.get(`/admin/users/` + id);
            if (res?.ok) {
                setUser(res?.data);
            } else {
                toast.error('Error in connection');
            }
        } catch (err) {
            toast.error(err?.message);
        }
    }

    useEffect(() => {
        getUserByID(id);
    }, [id])

    const handleSubmit = async (values) => {
        try {
            const res = await api.patch(`/admin/users/` + id, values);
            if (res && res?.ok) {
                let storedUserData = JSON.parse(localStorage.getItem("gie-user-data"));
                if (storedUserData?.user?._id === id) {
                    storedUserData.user = { ...storedUserData.user, ...values };
                    localStorage.setItem("gie-user-data", JSON.stringify(storedUserData));
                }
                toast.success(res?.message);
                history.push('/admin/users');
            } else {
                toast.error('Server connection error.');
            }
        } catch (err) {
            if (err?.errors) {
                toast.error(err?.errors[0]?.msg);
            } else {
                toast.error(err?.message);
            }
        }
    }
    return (
        <>
            <div className="container">
                <div className=" shadow bg-white">
                    <h3 className="px-4 py-4 mb-0">
                        Edit User
                    </h3>
                    <hr />
                    <Formik
                        initialValues={{
                            name: user?.name || '',
                            companyName: user?.companyName || '',
                            userName: user?.userName || '',
                            email: user?.email || '',
                            phone: user?.phone || '',
                            role: user?.role || 'user',
                            status: user?.status || 'active'
                        }}
                        enableReinitialize={true}
                        validationSchema={userSchema}
                        onSubmit={async (values) => {
                            await handleSubmit(values);
                        }}
                    >
                        <Form className="p-4">
                            <div className="row">
                                <div className="mb-3 col-md-12">
                                    <label htmlFor="name">Full Name</label>
                                    <Field id="name" name="name" className="form-control" />
                                    <ErrorMessage component={'div'} className="text-danger" name="name" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="userName">Username</label>
                                    <Field id="userName" name="userName" className="form-control" />
                                    <ErrorMessage component={'div'} className="text-danger" name="userName" />
                                </div>

                                <div className="mb-3 col-md-6">
                                    <label htmlFor="email">Email Address</label>
                                    <Field id="email" name="email" className="form-control" />
                                    <ErrorMessage component={'div'} className="text-danger" name="email" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="phone">Phone Number</label>
                                    <Field id="phone" name="phone" className="form-control" />
                                    <ErrorMessage component={'div'} className="text-danger" name="phone" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="companyName">Company Name</label>
                                    <Field id="companyName" name="companyName" className="form-control" />
                                    <ErrorMessage component={'div'} className="text-danger" name="companyName" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="role">Role</label>
                                    <Field as="select" name="role" id="role" className="form-control">
                                        <option value="user">User</option>
                                        <option value="superadmin">Super Admin</option>
                                        <option value="admin">Admin</option>
                                    </Field>
                                    <ErrorMessage component={'div'} className="text-danger" name="role" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="status">Status</label>
                                    <Field as="select" name="status" id="status" className="form-control">
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                        <option value="pending">Pending</option>
                                        <option value="banned">Banned</option>
                                    </Field>
                                    <ErrorMessage component={'div'} className="text-danger" name="status" />
                                </div>
                                <div className="col-md-12">
                                    <button type="submit" className="btn btn-primary">Save</button>
                                    <Link to={`/admin/users/`} className="btn btn-secondary ml-2">Cancel</Link>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div >
        </>
    );
};

export default EditUser;
