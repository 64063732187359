import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useHistory, useParams, Link } from "react-router-dom";
import api from "services/api";
import { useState, useEffect } from "react";
import { getProjectById } from "services/APIHelpers";

const projectSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    locationName: Yup.string().required('This field is required.'),
    instrumentName: Yup.string().required('This field is required.'),
    bcaReference: Yup.string().required('This field is required.'),
});
const EditProject = () => {
    const history = useHistory();
    const { id } = useParams();
    const [project, setProject] = useState({});
    const getProjectDetails = async (id) => {
        const res = await getProjectById(id);
        if (res && res?.ok) {
            setProject(res?.data);
        }
    }
    useEffect(() => {
        getProjectDetails(id);
    }, [id])

    const handleSubmit = async (values) => {
        try {
            const res = await api.patch(`/admin/projects/` + id, values);
            if (res && res?.ok) {
                toast.success(res?.message);
                history.push('/admin/projects');
            } else {
                toast.error('Server connection error.');
            }
        } catch (err) {
            if (err?.errors) {
                toast.error(err?.errors[0]?.msg);
            } else {
                toast.error(err?.message);
            }
        }
    }
    return (
        <>
            <div className="container">
                <div className="shadow bg-white">
                    <h3 className="px-4 py-4 mb-0">
                        Edit Project
                    </h3>
                    <hr />
                    <Formik
                        initialValues={{
                            name: project.name || '',
                            locationName: project?.locationId?.name || '',
                            locationDetails: project?.locationId?.description || '',
                            bcaReference: project?.bcaReference || '',
                            contractorName: project?.contractorName || '',
                            description: project?.description || '',
                            startDate: project?.startDate?.substring(0, 10) || '',
                            remarks: project?.remarks || '',
                            instrumentName: project?.instrumentName || '',
                            instrumentBrand: project?.instrumentBrand || '',
                            instrumentModel: project?.instrumentModel || '',
                            instrumentSerialNo: project?.instrumentSerialNo || '',
                            calibrationDate: project?.calibrationDate?.substring(0, 10) || '',
                            latitude: project?.latitude || '',
                            longitude: project?.longitude || '',
                            altitude: project?.altitude || '',
                            status: project?.status || 'active',
                        }}
                        enableReinitialize={true}
                        validationSchema={projectSchema}
                        onSubmit={async (values) => {
                            await handleSubmit(values);
                        }}
                    >
                        <Form className="p-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <strong>Project setting</strong>
                                    <hr />
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label htmlFor="name">Project Name</label>
                                    <Field id="name" name="name" className="form-control" />
                                    <ErrorMessage
                                        component={"div"}
                                        className="text-danger"
                                        name="name"
                                    />
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label htmlFor="locationName">Location Name</label>
                                    <Field
                                        id="locationName"
                                        name="locationName"
                                        className="form-control"
                                    />
                                    <ErrorMessage
                                        component={"div"}
                                        className="text-danger"
                                        name="locationName"
                                    />
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label htmlFor="instrumentName">Instrument Name</label>
                                    <Field
                                        id="instrumentName"
                                        name="instrumentName"
                                        className="form-control"
                                    />
                                    <ErrorMessage
                                        component={"div"}
                                        className="text-danger"
                                        name="instrumentName"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <strong>Project information</strong>
                                    <hr />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="bcaReference">BCA Reference No.</label>
                                    <Field
                                        id="bcaReference"
                                        name="bcaReference"
                                        className="form-control"
                                    />
                                    <ErrorMessage
                                        component={"div"}
                                        className="text-danger"
                                        name="bcaReference"
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="contractorName">Contractor's Name</label>
                                    <Field
                                        id="contractorName"
                                        name="contractorName"
                                        className="form-control"
                                    />
                                    <ErrorMessage
                                        component={"div"}
                                        className="text-danger"
                                        name="contractorName"
                                    />
                                </div>
                                <div className="mb-3 col-md-12">
                                    <label htmlFor="description">Project Details</label>
                                    <Field
                                        as="textarea"
                                        id="description"
                                        name="description"
                                        className="form-control"
                                    />
                                    <ErrorMessage
                                        component={"div"}
                                        className="text-danger"
                                        name="description"
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="locationDetails">Location Details</label>
                                    <Field
                                        id="locationDetails"
                                        name="locationDetails"
                                        className="form-control"
                                    />
                                    <ErrorMessage
                                        component={"div"}
                                        className="text-danger"
                                        name="locationDetails"
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="startDate">Start Date</label>
                                    <Field
                                        id="startDate"
                                        name="startDate"
                                        className="form-control"
                                        type="date"
                                    />
                                    <ErrorMessage
                                        component={"div"}
                                        className="text-danger"
                                        name="startDate"
                                    />
                                </div>
                                <div className="mb-3 col-md-12">
                                    <label htmlFor="remarks">Remarks</label>
                                    <Field
                                        as="textarea"
                                        id="remarks"
                                        name="remarks"
                                        className="form-control"
                                    />
                                    <ErrorMessage
                                        component={"div"}
                                        className="text-danger"
                                        name="remarks"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <strong>Instrument information</strong>
                                    <hr />
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label htmlFor="instrumentBrand">Brand</label>
                                    <Field
                                        id="instrumentBrand"
                                        name="instrumentBrand"
                                        className="form-control"
                                    />
                                    <ErrorMessage
                                        component={"div"}
                                        className="text-danger"
                                        name="instrumentBrand"
                                    />
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label htmlFor="instrumentModel">Model</label>
                                    <Field
                                        id="instrumentModel"
                                        name="instrumentModel"
                                        className="form-control"
                                    />
                                    <ErrorMessage
                                        component={"div"}
                                        className="text-danger"
                                        name="instrumentModel"
                                    />
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label htmlFor="instrumentSerialNo">Serial No.</label>
                                    <Field
                                        id="instrumentSerialNo"
                                        name="instrumentSerialNo"
                                        className="form-control"
                                    />
                                    <ErrorMessage
                                        component={"div"}
                                        className="text-danger"
                                        name="instrumentSerialNo"
                                    />
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label htmlFor="calibrationDate">Calibration Date</label>
                                    <Field
                                        id="calibrationDate"
                                        name="calibrationDate"
                                        className="form-control"
                                        type="date"
                                    />
                                    <ErrorMessage
                                        component={"div"}
                                        className="text-danger"
                                        name="calibrationDate"
                                    />
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label htmlFor="latitude">Latitude</label>
                                    <Field
                                        id="latitude"
                                        name="latitude"
                                        className="form-control"
                                    />
                                    <ErrorMessage
                                        component={"div"}
                                        className="text-danger"
                                        name="latitude"
                                    />
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label htmlFor="longitude">Longitude</label>
                                    <Field
                                        id="longitude"
                                        name="longitude"
                                        className="form-control"
                                    />
                                    <ErrorMessage
                                        component={"div"}
                                        className="text-danger"
                                        name="longitude"
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="longitude">Altitude</label>
                                    <Field
                                        id="altitude"
                                        name="altitude"
                                        className="form-control"
                                    />
                                    <ErrorMessage
                                        component={"div"}
                                        className="text-danger"
                                        name="altitude"
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="status">Status</label>
                                    <Field as="select" name="status" id="status" className="form-control">
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                    </Field>
                                    <ErrorMessage component={'div'} className="text-danger" name="status" />
                                </div>
                                <div className="col-md-12">
                                    <button type="submit" className="btn btn-primary">
                                        Save
                                    </button>
                                    <Link to={`/admin/projects`} className="btn btn-secondary ml-2">Cancel</Link>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default EditProject;
