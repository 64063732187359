import Chart from "components/Chart";
import { useEffect, useState } from "react";
import api from "services/api";
import moment from 'moment';
import ReadingTable from "components/ReadingTable";

export const CustomCard = ({project, graphId, startDate, endDate, type, projectName }) => {
    const mStartDate = moment(startDate).format('DD/MMM/YY');
    const mEndDate = moment(endDate).format('DD/MMM/YY');
    const [categories, setCategories] = useState([]);
    const [readingHeader, setReadingHeader] = useState([]);
    const [measurementUnit, setMeasurementUnit] = useState('');
    const [dataset, setDataSet] = useState([]);
    const getSensorData = async (graphId, startDate, endDate) => {
        const response = await api.get(`/admin/sensors/sensorData/` + graphId, {
            params: {
                startDate,
                endDate
            }
        });
        if (response) {
            let categoriesData = [];
            let dataValues = [];
            let mReadingHeader = ['Date', 'Time'];
            response?.data?.forEach((item, index) => {
                mReadingHeader.push(item.name + ' (' + item.measurementUnit + ')');
                let readings = [];
                let alValue = [];
                let wslValue = [];
                if (item?.sensorData) {
                    item?.sensorData.forEach((sData) => {
                        if (index === 0) {
                            const date = moment(sData?.callibrationDate).format('DD/MMM/YY HH:mm');
                            categoriesData.push({ label: date });
                            alValue.push({ value: item?.alertLevel });
                            wslValue.push({ value: item?.workSuspensionLevel });
                        }
                        readings.push({ value: sData?.reading1 });
                    })
                }
                if (index === 0) {
                    setMeasurementUnit(item?.measurementUnit);
                    dataValues.push({
                        seriesname: "AL",
                        color: "#008000",
                        drawanchors: "0",
                        data: alValue
                    });
                    dataValues.push({
                        seriesname: "WSL",
                        color: "#fd6480",
                        drawanchors: "0",
                        data: wslValue
                    });
                }
                let color = '';
                if (item?.name.search('PVS') !== -1) {
                    color = "#0aabec";
                }
                if (item?.name.search('Xppv') !== -1) {
                    color = "#254a0a";
                }
                if (item?.name.search('Yppv') !== -1) {
                    color = "#3357cc";
                }
                if (item?.name.search('Zppv') !== -1) {
                    color = "#94195f";
                }
                if (item?.name.search('Xacc') !== -1) {
                    color = "#1d3b07";
                }
                if (item?.name.search('Yacc') !== -1) {
                    color = "#203885";
                }
                if (item?.name.search('Zacc') !== -1) {
                    color = "#61133f";
                }
                if (item?.name.search('Xdisp') !== -1) {
                    color = "#88ed3e";
                }
                if (item?.name.search('Ydisp') !== -1) {
                    color = "#12245c";
                }
                if (item?.name.search('Zdisp') !== -1) {
                    color = "#360a22";
                }
                if (item?.name.search('Xfreq') !== -1) {
                    color = "#4d8a21";
                }
                if (item?.name.search('Yfreq') !== -1) {
                    color = "#091330";
                }
                if (item?.name.search('Zfreq') !== -1) {
                    color = "#2b041a";
                }
                dataValues.push({
                    seriesname: item?.name,
                    color,
                    data: readings
                });
            });
            setCategories(categoriesData);
            setDataSet(dataValues);
            setReadingHeader(mReadingHeader);
        }

    }
    useEffect(() => {
        getSensorData(graphId, startDate, endDate);
    }, [graphId, startDate, endDate]);
    return (
        <>
            <Chart 
                project = {project}
                projectName = {projectName}
                caption={mStartDate + ' to ' + mEndDate}
                yaxisname={type + ' (' + measurementUnit + ')'}
                categories={categories}
                dataset={dataset}
                measurementUnit={measurementUnit}
            />
            {readingHeader.length > 0 &&
                <ReadingTable
                    startDate={mStartDate}
                    endDate={mEndDate}
                    type={type}
                    readingHeader={readingHeader}
                    categories={categories}
                    dataset={dataset}
                />
            }

        </>
    );
}