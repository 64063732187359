import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from "yup";
import { toast } from 'react-toastify'
import api from 'services/api';
const loginSchema = Yup.object().shape({
  userName: Yup.string().required('This field is required'),
  password: Yup.string().required('This field is required.')
});
const Login = () => {
  toast.configure();
  const handleSubmit = async (values, setErrors) => {
    try {
      const res = await api.post('/auth/login', values);
      if (res && res?.ok) {
        localStorage.setItem('gie-user-data', JSON.stringify(res));
        toast.success(res?.message);
        window.location = "/admin/dashboard";
      } else {
        toast.error('Server connection error.');
      }
    } catch (err) {
      if (err?.errors) {
        let fieldsError = {};
        err.errors.forEach((item) => {
          fieldsError[item.path] = item.msg;
        });
        setErrors(fieldsError);
      } else {
        toast.error(err?.message);
      }
    }
  }
  return (
    <>
      <Formik
        initialValues={{
          userName: '',
          password: ''
        }}
        validationSchema={loginSchema}
        onSubmit={async (values, { setErrors }) => {
          await handleSubmit(values, setErrors);
        }}
      >
        <div className="Auth-form-container">
          <Form className="Auth-form">
            <div className="Auth-form-content">
              <h3 className="Auth-form-title">Sign In</h3>
              <div className="form-group mt-5">
                <label htmlFor="name">Username</label>
                <Field id="userName" name="userName" className="form-control" />
                <ErrorMessage component={'div'} className="text-danger" name="userName" />
              </div>
              <div className="form-group mt-5">
                <label htmlFor="name">Password</label>
                <Field id="password" name="password" className="form-control" type="password" />
                <ErrorMessage component={'div'} className="text-danger" name="password" />
              </div>
              <div className="d-grid gap-2 mt-5 text-center ">
                <button type="submit" className="py-2 px-4 rounded " style={{ backgroundColor: "#ef4444", color: "white" }}>
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Formik>
    </>
  )
}

export default Login;