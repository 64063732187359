import React, { useState, useContext } from "react";
import { createContext } from "react";

export const GlobalContext = createContext();

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

const GlobalContextProvider = ({ children }) => {
  const [defaultProjectId, setDefaultProjectId] = useState();

  return (
    <GlobalContext.Provider
      value={{defaultProjectId, setDefaultProjectId }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
export default GlobalContextProvider;
