import { useLocation, NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import logo from "assets/img/logo.png";
import { role } from "index";

function Sidebar({ color, image, routes }) {
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url(" + image + ")",
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-center">
          <NavLink className="simple-text logo-mini mx-1" to="/">
            <div className="logo-img">
              <img src={logo} alt="..." />
            </div>
          </NavLink>
        </div>
        <Nav>
          {routes
            ?.filter((val) => val?.role?.includes(role))
            .map((prop, key) => {
              if (!prop.redirect)
                return (
                  <li
                    className={
                      prop.upgrade
                        ? "active active-pro"
                        : activeRoute(prop.layout + prop.path)
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </NavLink>
                  </li>
                );
            })}
          {role !== undefined &&
            <li>
              <a href={'/'} className="nav-link"
                onClick={(e) => {
                  e.preventDefault();
                  localStorage.clear();
                  window.location = "/admin/login";
                }}>
                <i className="nc-icon nc-button-power"></i>
                <p>Logout</p>
              </a>
            </li>
          }
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
