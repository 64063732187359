import { useContext, useEffect, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import MyTable from "components/Table";
import { Link } from "react-router-dom";
import { FiX } from "react-icons/fi";
import Select from "react-select";
import { getAllProjects } from "services/APIHelpers";
import { getAssignedUsersByProjectId } from "services/APIHelpers";
import { deleteAssignedUserById } from "services/APIHelpers";
import { useGlobalContext } from "context/Provider";
function ListAssignedUser() {
  const [projects, setProjects] = useState([]);
  const [defaultProject, setDefaultProject] = useState({});
  const [items, setItems] = useState([]);
  const { defaultProjectId, setDefaultProjectId } = useGlobalContext();
  const getProjects = async () => {
    const res = await getAllProjects();
    if (res.length > 0) {
      let projectOptionsData = [];
      res.forEach((item, index) => {
        let projectItem = {
          value: item?._id,
          label: item?.name,
        };
        if (defaultProjectId && defaultProjectId === item?._id) {
          setDefaultProject(projectItem);
        } else if (index === 0) {
          setDefaultProject(projectItem);
        }
        projectOptionsData.push(projectItem);
      });
      setProjects(projectOptionsData);
    }
  };

  const getUsersByProject = async (id) => {
    const res = await getAssignedUsersByProjectId(id);
    if (res.length > 0) {
      let userItems = [];
      res?.forEach((item) => {
        let userItem = {
          id: item._id,
          name: item?.userId?.name || "-",
          email: item?.userId?.email || "-",
          phone: item?.userId?.phone || "-",
          companyName: item?.userId?.companyName || "-",
          userName: item?.userId?.userName || "-",
        };
        userItems.push(userItem);
      });
      setItems(userItems);
    } else {
      setItems([]);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    if (defaultProject?.value) {
      getUsersByProject(defaultProject?.value);
    }
  }, [defaultProject]);

  const handleDelete = async (id) => {
    if (confirm("Are you sure?")) {
      const res = await deleteAssignedUserById(id);
      if (res && res?.ok) {
        toast.success(res?.message);
        if (defaultProject?.value) {
          getUsersByProject(defaultProject?.value);
        }
      } else {
        toast.error("Unable to delete this user.");
      }
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      hidden: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "userName",
      text: "userName",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <div key={cellContent}>
            <button
              type="button"
              onClick={() => handleDelete(row?.id)}
              className="btn btn-danger ml-2 p-1 px-2"
            >
              <FiX />
            </button>
          </div>
        );
      },
      align: "center",
    },
  ];
  const handleSelectChange = (e) => {
    let projectId = e.value;
    setDefaultProject(e);
    setDefaultProjectId(projectId);
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover p-4 pb-0 shadow">
              <Card.Header className="d-flex justify-content-between align-items-center p-0">
                <div className="w-75">
                  <Card.Title as="h4" className="mb-2">
                    Assigned users to project
                  </Card.Title>
                  {projects && defaultProject?.value && (
                    <div className="w-240 mb-2 gie-select">
                      <Select
                        defaultValue={defaultProject}
                        options={projects}
                        onChange={handleSelectChange}
                      />
                    </div>
                  )}
                </div>
                <div className="w-25  d-flex justify-content-end">
                  <Link
                    to={`/admin/assign-user/` + defaultProject?.value}
                    className="btn btn-primary p-1 px-3"
                  >
                    Assign User
                  </Link>
                </div>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <MyTable items={items} columns={columns} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default ListAssignedUser;
