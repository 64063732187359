import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import api from "services/api";
import { toast } from "react-toastify";

const settingsSchema = Yup.object().shape({
  userName: Yup.string().required("This Field is required"),
  name: Yup.string().required("This Field is required"),
});

const GeneralSettings = () => {
  const [user, setUser] = useState([]);

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("gie-user-data"));
    if (storedUserData) {
      setUser(storedUserData.user);
    }
  }, []);

  const handleSubmit = async (values) => {
    try {
      const res = await api.post(`/admin/profile/edit`, values);
      if (res && res?.ok) {
        toast.success(res?.message);
        let storedUserData = JSON.parse(localStorage.getItem("gie-user-data"));
        storedUserData.user = {...storedUserData.user , ...values};
        localStorage.setItem("gie-user-data", JSON.stringify(storedUserData));
      } else {
        toast.error("Server connection error.");
      }
    } catch (err) {
      if (err?.errors) {
        toast.error(err?.errors[0]?.msg);
      } else {
        toast.error(err?.message);
      }
    };
  };
  return (
    <Formik
      initialValues={{
        name: user?.name || "",
        phone: user?.phone || "",
        companyName: user?.companyName || "",
        email: user?.email || "",
        userName: user?.userName || "",
      }}
      enableReinitialize={true}
      validationSchema={settingsSchema}
      onSubmit={async (values) => {
        await handleSubmit(values);
      }}
    >
      <Form>
        <div className="row ">
          <div className="form-group col-md-12">
            <label htmlFor="name">Full Name</label>
            <Field id="name" name="name" className="form-control" />
            <ErrorMessage
              component={"div"}
              className="text-danger"
              name="name"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="phone">Phone Number</label>
            <Field
              id="phone"
              name="phone"
              className="form-control"
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="companyName">Company Name</label>
            <Field
              id="companyName"
              name="companyName"
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="email">Email Address</label>
            <Field
              id="email"
              name="email"
              className="form-control"
              type="email"
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="userName">Username</label>
            <Field
              id="userName"
              name="userName"
              className="form-control"
            />
            <ErrorMessage
              component={"div"}
              className="text-danger"
              name="userName"
            />
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          Save
        </button>
      </Form>
    </Formik>
  );
};

export default GeneralSettings;
