import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { role } from "index";
import { Link } from "react-router-dom";

function Header() {
  let storedUserData = JSON.parse(localStorage.getItem("gie-user-data"));
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid className="">
        <div className="d-flex justify-content-center  ml-2 ml-lg-0">
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}
          >
            <i className="fas fa-ellipsis-v"></i>
          </Button>
          <Navbar.Brand
            className="mr-2"
          >
            Hi, {storedUserData?.user?.name || 'User'}
          </Navbar.Brand>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle>
        {role && <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav mr-auto" navbar>
            <Nav.Item>
              <Nav.Link
                data-toggle="dropdown"
                href="#"
                onClick={(e) => e.preventDefault()}
                className="m-0"
              >
                <span className="d-lg-none ml-1">Dashboard</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav className="ml-auto" navbar>
            <Nav.Item>
              <Link
                className="m-0 nav-link"
                to={'/admin/account-setting'}
              >
                <span className="no-icon">Account</span>
              </Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                href={'/'}
                className="m-0"
                onClick={(e) => {
                  e.preventDefault();
                  localStorage.clear();
                  window.location = "/admin/login";
                }}
              >
                <span className="no-icon">Log out</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>}
      </Container>
    </Navbar>
  );
}

export default Header;
