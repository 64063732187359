import { useEffect, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import api from "services/api";
import { toast } from "react-toastify";
import MyTable from "components/Table";
import { Link } from "react-router-dom";
import { FiEdit, FiX } from "react-icons/fi";
import { getAllUsers } from "services/APIHelpers";
function ListUser() {
  const [items, setItems] = useState([]);
  const getUsers = async () => {
    try {
      const res = await getAllUsers();
      if (res.length > 0) {
        let userItems = [];
        res.forEach((item) => {
          let userItem = {
            id: item?._id,
            name: item?.name || '-',
            email: item?.email || '-',
            phone: item?.phone || '-',
            companyName: item?.companyName || '-',
            userName: item?.userName || '-',
          };
          userItems.push(userItem);
        });
        setItems(userItems);
      } else {
        toast.error('Error in connection');
      }
    } catch (err) {
      toast.error(err?.message);
    }
  }
  useEffect(() => {
    getUsers();
  }, []);

  const handleDelete = async (id) => {
    if (confirm('Are you sure?')) {
      try {
        const res = await api.delete(`/admin/users/` + id);
        if (res?.ok) {
          toast.success(res?.message);
          getUsers();
        }
      } catch (err) {
        toast.error(err?.message);
      }
    }
  }
  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'phone',
      text: 'Mobile No.',
      sort: true
    },
    {
      dataField: 'email',
      text: 'Email Address',
      sort: true
    },
    {
      dataField: 'companyName',
      text: 'Company Name',
      sort: true
    },
    {
      dataField: 'userName',
      text: 'userName',
      sort: true
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cellContent, row) => {
        return (
          <div key={cellContent}>
            <Link to={`/admin/user/edit/` + row?.id} className="btn btn-primary p-1 px-2">
              <FiEdit />
            </Link>
            <button type="button" onClick={() => handleDelete(row?.id)} className="btn btn-danger ml-2 p-1 px-2">
              <FiX />
            </button >
          </div>
        )
      },
      align: "center"
    }
  ];
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover p-4 pb-0 shadow">
              <Card.Header className="d-flex justify-content-between align-items-center p-0">
                <Card.Title as="h4" className="w-75">
                  Users
                </Card.Title>
                <div className="w-25  d-flex justify-content-end">
                  <Link to={'/admin/user/create'} className="btn btn-primary p-1 px-3">Add New</Link>
                </div>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <MyTable items={items} columns={columns} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default ListUser;
