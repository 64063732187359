import MyTable from "components/Table";
import { useEffect } from "react";
import { useState } from "react";
import { FiEdit, FiEye, FiSettings, FiTarget, FiX } from "react-icons/fi";
import api from "services/api";
import { Link } from "react-router-dom";
import { getAllProjects } from "services/APIHelpers";
import { toast } from "react-toastify";

const AdminProjects = () => {
    const [items, setItems] = useState([]);
    const getProjects = async () => {
        const res = await getAllProjects();
        if (res.length > 0) {
            let projectItems = [];
            res?.forEach((item) => {
                let projectItem = {
                    id: item?._id,
                    name: item?.name,
                    locationName: item?.locationId?.name || '-',
                    instrumentName: item?.instrumentName || '-',
                    contractorName: item?.contractorName || '-',
                };
                projectItems.push(projectItem);
            });
            setItems(projectItems);
        }
    }
    useEffect(() => {
        getProjects();
    }, []);
    const handleDelete = async (id) => {
        if (confirm('Are you sure?')) {
            try {
                const res = await api.delete(`/admin/projects/` + id);
                if (res?.ok) {
                    toast.success(res?.message);
                    getProjects();
                }
            } catch (err) {
                toast.error(err?.message);
            }
        }
    }
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            hidden: true
        },
        {
            dataField: 'name',
            text: 'Project Name',
            sort: true
        },
        {
            dataField: 'locationName',
            text: 'Location Name',
            sort: true
        },
        {
            dataField: 'instrumentName',
            text: 'Instrument Name',
            sort: true
        },
        {
            dataField: 'contractorName',
            text: 'Company Name',
            sort: true
        },
        {
            dataField: 'action',
            text: 'Action',
            formatter: (cellContent, row) => {
                return (
                    <div key={cellContent}>
                        <Link to={`/admin/project/view/` + row?.id} className="btn btn-primary p-1" title="Sensor View">
                            <FiEye />
                        </Link>
                        <Link to={`/admin/project/edit/` + row?.id} className="btn ml-2 btn-primary p-1" title="Project Setting">
                            <FiEdit />
                        </Link>
                        <Link to={`/admin/project/sensor-setting/` + row?.id} className="btn ml-2 btn-primary p-1" title="Sensor Setting">
                            <FiSettings />
                        </Link >
                        <Link to={`/admin/project/profile-setting/` + row?.id} className="btn ml-2 btn-primary p-1" title="Graph profile">
                            <FiTarget />
                        </Link >
                        <button type="button" onClick={() => handleDelete(row?.id)} className="btn ml-2 btn-danger p-1" title="Delete Project">
                            <FiX />
                        </button >
                    </div>
                )
            },
            align: "center"
        }
    ];
    return (
        <>
            <MyTable items={items} columns={columns} />
        </>
    )
}
export default AdminProjects;
