import React, { useState } from "react";
import GeneralSettings from "./GeneralSettings";
import SecuritySettings from "./SecuritySettings";

const AccountSetting = () => {
  const [toggleSetting, setToggleSettings] = useState(false);

  return (
    <>
      <div className="container">
        <div className=" bg-white shadow pb-2">
          <div className="d-flex px-0 settings-nav">
            <p
              onClick={() => setToggleSettings(false)}
              role="button"
              className={`${
                toggleSetting ? null : "settings-active"
              } py-2 px-3 settings-btn my-0`}
            >
              Profile Settings
            </p>
            <p
              onClick={() => setToggleSettings(true)}
              role="button"
              className={`${
                toggleSetting ? "settings-active" : null
              } py-2 px-3 settings-btn my-0 `}
            >
              Change Password
            </p>
          </div>

          <div className="px-4 py-5 ">
            {toggleSetting ? <SecuritySettings /> : <GeneralSettings />}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSetting;
