import { useParams, Link } from "react-router-dom";
import ProjectName from "../Project/ProjectName";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import MyTable from "components/Table";
import api from "services/api";
import { FiEdit, FiList, FiX } from "react-icons/fi";
import { getGraphsByProjectId } from "services/APIHelpers";
const { Container, Row, Col, Card } = require("react-bootstrap")

const GraphProfileList = () => {
    const { id } = useParams();
    const [items, setItems] = useState([]);
    const getGraphProfilesByProjectId = async (projectId) => {
        const res = await getGraphsByProjectId(projectId);
        if (res.length > 0) {
            let dataItems = [];
            res.forEach((item) => {
                let dataItem = {
                    id: item?._id,
                    name: item?.name,
                    sensorCount: item?.sensors.length || 0,
                };
                dataItems.push(dataItem);
            });
            setItems(dataItems);
        }
    }
    useEffect(() => {
        getGraphProfilesByProjectId(id);
    }, [id]);
    const handleDelete = async (graphId) => {
        if (confirm('Are you sure?')) {
            try {
                const res = await api.delete(`/admin/graph-profiles/` + graphId);
                if (res?.ok) {
                    toast.success(res?.message);
                    getGraphProfilesByProjectId(id);
                }
            } catch (err) {
                toast.error(err?.message);
            }
        }
    }
    const columns = [
        {
            dataField: 'id',
            sort: true,
            hidden: true
        },
        {
            dataField: 'name',
            text: 'Graph Profile Name',
            sort: true
        },
        {
            dataField: 'sensorCount',
            text: 'Sensor Count',
            sort: true
        },
        {
            dataField: 'action',
            text: 'Action',
            formatter: (cellContent, row) => {
                return (
                    <div key={cellContent}>
                        <Link to={`/admin/graph-profile/` + id + `/select-sensor/` + row?.id} className="btn btn-primary p-1 px-2" title="Select Sensor">
                            <FiList />
                        </Link>
                        <Link to={`/admin/graph-profile/` + id + `/edit/` + row?.id} className="btn btn-primary ml-2 p-1 px-2" title="Edit Graph Profile">
                            <FiEdit />
                        </Link>
                        <button type="button" onClick={() => handleDelete(row?.id)} className="btn btn-danger ml-2 p-1 px-2" title="Delete Graph Profile">
                            <FiX />
                        </button >
                    </div>
                )
            },
            align: "center"
        }
    ];
    return (
        <>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover p-4 pb-0 shadow">
                            <Card.Header className="d-flex justify-content-between align-items-center p-0">
                                <div>
                                    <h3>Graph Profile Setting</h3>
                                    <ProjectName id={id} />
                                </div>
                                <div className="w-25  d-flex justify-content-end">
                                    <Link to={`/admin/create-graph-profile/` + id} className="btn btn-primary">Add new</Link>
                                </div>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <MyTable items={items} columns={columns} />

                            </Card.Body>

                        </Card>
                        <div className="mt-2">
                            <Link to={`/admin/projects/`} className="btn btn-secondary">Cancel</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default GraphProfileList;