import Listing from "components/Listing";
import { getAllUsers } from "services/APIHelpers";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import { getAssignedUsersByProjectId } from "services/APIHelpers";
import { assignUserToProject } from "services/APIHelpers";
import { toast } from "react-toastify";
import ProjectName from "views/Project/ProjectName";

const AssignUser = () => {
    const { id } = useParams();
    const history = useHistory();
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [error, setError] = useState('');
    const getUsers = async () => {
        const res = await getAllUsers();
        let userData = [];
        if (res) {
            res.forEach((item) => {
                const listingData = {
                    id: item._id,
                    name: item?.name || ''
                }
                userData.push(listingData);
            });
            setItems(userData);
        }
    }
    const getAssignedUsersByProject = async (id) => {
        const res = await getAssignedUsersByProjectId(id);
        let userData = [];
        if (res) {
            res.forEach((item) => {
                const listingData = {
                    id: item?.userId?._id,
                    name: item?.userId?.name || ''
                }
                userData.push(listingData);
            });
            setSelectedItems(userData);
        }
    }
    useEffect(() => {
        getUsers();
        getAssignedUsersByProject(id);
    }, [id]);
    const handleSubmit = async () => {
        if (selectedItems.length === 0) {
            setError('Please add at least one user.');
        } else {
            setError('');
            const res = selectedItems.map(({ id }) => id);
            const values = {
                users: res,
                projectId: id
            }
            const response = await assignUserToProject(values);
            if (response && response?.ok) {
                toast.success(response?.message);
                history.push('/admin/assigned-users');
            } else {
                toast.error('Error in assigning user.');
            }
        }
    }
    return (
        <>
            <Container fluid>
                <div className="shadow bg-white shadow-lg rounded-lg p-4">
                    <div>
                        <h3>Assign user to project</h3>
                        <ProjectName id={id} />
                    </div>
                    <hr className="mb-4" />
                    {items.length > 0 && <Listing items={items} selectedItems={selectedItems} setSelectedItems={setSelectedItems} />}
                    {error && <div className="text-danger">{error}</div>}
                    <div className="text-right d-block">
                        <Button variant="primary" size="md" onClick={() => handleSubmit()}>
                            Save
                        </Button>
                        <Link to={'/admin/assigned-users'} className="ml-2 btn btn-secondary">Cancel</Link>
                    </div>
                </div>
            </Container >
        </>
    );
}
export default AssignUser;